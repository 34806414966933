import type { ContextType } from '~/core/ts/entity/ContextType'

import { includesInsensitive, isADynamicURL, unslashUri } from '~/core/ts/util/string'
import { removeAnyLangFromURL } from '~/core/ts/util/translate'

export function useContext() {
  const route = useRoute()
  const runtime = useRuntimeConfig()

  const type = computed((): ContextType => {
    const url = route.path

    if (isADynamicURL(url)) {
      return 'dynamic'
    }

    if (includesInsensitive(url, 'service-booking')) {
      return 'dynamic'
    }

    if (includesInsensitive(url, runtime.public.urls.spasBaseUrl)) {
      return 'spas'
    }

    if (includesInsensitive(url, runtime.public.urls.getAwayBaseUrl)) {
      return 'getAways'
    }

    if (includesInsensitive(url, runtime.public.urls.promotionBaseUrl)) {
      return 'promotions'
    }

    if (unslashUri(removeAnyLangFromURL(url)) === '') {
      return 'homes'
    }

    return 'treatments'
  })

  return {
    type,
  }
}
