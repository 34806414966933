<template>
  <div class="redsys-challenge">
    <Card class="redsys-challenge__box">
      <LoadingSpinner />
    </Card>

    <div v-if="challengeVersion === '1'">
      <form
        id="ver-1-form"
        :action="meta.acsURL"
        method="post"
      >
        <input
          type="hidden"
          name="MD"
          :value="meta.MD"
        >
        <input
          type="hidden"
          name="PaReq"
          :value="meta.PAReq"
        >
        <input
          type="hidden"
          name="TermUrl"
          :value="termUrl"
        >
      </form>
    </div>

    <div v-if="challengeVersion === '2'">
      <form
        id="ver-2-form"
        :action="meta.acsURL"
        method="post"
      >
        <input
          type="hidden"
          name="creq"
          :value="meta.creq"
        >
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import { useVoucherConfirmation } from '~/core/composable/voucher-purchase/useVoucherConfirmation'
import LoadingSpinner from '~/core/components/shared/LoadingSpinner.vue'
import { VoucherPurchase } from '~/core/ts/entity/VoucherPurchase'

import LoadingEventBus from '~/core/events/loading'
import NotificationEventBus from '~/core/events/notification'

export default defineNuxtComponent({
  components: {
    LoadingSpinner,
  },
  layout: 'checkout',
  setup() {
    const route = useRoute()
    const router = useRouter()
    const runtimeConfig = useRuntimeConfig()

    const { voucherUUID, getVoucherPurchase } = useVoucherConfirmation()
    const meta = ref<null | any>(null)

    const finishUrl = route.path.replace('redsys/challenge', 'confirmation')

    const termUrl = ref<string>('')

    onMounted(async () => {
      window.scrollTo(0, 0)

      LoadingEventBus.show()
      const purchase = await getVoucherPurchase()
      LoadingEventBus.hide()

      if (!purchase) {
        NotificationEventBus.show(
          'an-error-has-ocurred',
          'voucher-cannot-be-located-support'
        )
        return
      }

      if (!VoucherPurchase.isRedsysChallengeRequest(purchase)) {
        // Hemos llegado a esta página por alguna razón, salimos automáticamente
        await router.push({ path: finishUrl })
        return
      }

      const lemeta: any = VoucherPurchase.readAuthorizedMetadata(purchase)

      const currentPayment = VoucherPurchase.getActivePayment(purchase)
      if (currentPayment) {
        const domain = `${runtimeConfig.public.apiBaseURL}/api/v1/web/`
        const path = `voucher-purchase/${voucherUUID}/payment/RedsysInSitePaymentMethod/confirm/${currentPayment.uuid}/redirect`

        termUrl.value = (domain + path)
      }

      meta.value = (lemeta.Ds_EMV3DS)

      setTimeout(() => {
        let form: HTMLElement | null = document.getElementById('ver-1-form')

        if (!form) {
          form = document.getElementById('ver-2-form')
        }

        if (form) {
          (form as HTMLFormElement).submit()
        }
      }, 800)
    })

    const challengeVersion = computed(() => {
      if (!meta.value) {
        return ''
      }

      if (!meta.value.protocolVersion) {
        return ''
      }

      if (meta.value.protocolVersion.startsWith('1')) {
        return '1'
      }

      if (meta.value.protocolVersion.startsWith('2')) {
        return '2'
      }

      return ''
    })

    return {
      meta,
      finishUrl,
      termUrl,
      challengeVersion,
    }
  },
})
</script>

<style lang="scss" scoped>
.redsys-challenge {
  @apply my-12;
  @apply mx-2;

  &__box {
    @apply text-center;

    @apply mt-8;
    @apply mb-12;

    @apply pt-4;
    @apply pb-6;

    &__title {
      @apply text-spl-primary-dark;
      @apply text-2xl;
      @screen lg {
        @apply text-3xl;
      }

      @apply my-2;
    }
  }

  @screen lg {
    @apply w-2/3;
    @apply mx-auto;
  }
}
</style>
