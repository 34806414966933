import { GetAwayPurchase } from '../ts/entity/GetAwayPurchase'
import type { Service } from '../ts/entity/Service'
import type { Spa } from '../ts/entity/Spa'
import { VoucherPurchase } from '../ts/entity/VoucherPurchase'
import EventBus from './event-bus'
import { translate } from '../ts/util/translate'


export default class GA4AnaliticsEventBus {
  static on(func: any): number {
    return EventBus.on('ga4-event', func)
  }

  static off(id: number) {
    return EventBus.off('ga4-event', id)
  }

  static send(name: string, meta: object | undefined) {
    EventBus.emit('ga4-event', name, meta)
  }

  public static sendAddToCartVoucher(purchase: VoucherPurchase) {
    const numericPrice = purchase.pricing.final.amount / 100

    const items = this.mapVouchersToItems(purchase)

    const transaction = purchase.locator.purchaseLocator
    const voucherCode = this.getVoucherCode(purchase)

    const meta = {
      transaction_id: transaction,
      voucher_id: voucherCode,

      currency: purchase.pricing.final.currency,
      items,
      affiliation: 'Spalopia',
      value: numericPrice,
    }

    this.send('add_to_cart', meta)
  }

  public static sendAddToCartGetAway(purchase: GetAwayPurchase) {
    const rate = GetAwayPurchase.getSelectedRate(purchase)
    if (!rate) {
      return
    }

    const numericPrice = rate.basePriceWithDiscount.amount / 100

    const items = this.mapGetAwayToItems(purchase)

    const transaction = purchase.locators.purchase

    const meta = {
      transaction_id: transaction,

      currency: rate.basePriceWithDiscount.currency,
      items,
      affiliation: 'Spalopia',
      value: numericPrice,
    }

    this.send('add_to_cart', meta)
  }

  static sendAddShippingInfoVoucher(purchase: VoucherPurchase) {
    const numericPrice = purchase.pricing.final.amount / 100

    const items = this.mapVouchersToItems(purchase)

    const transaction = purchase.locator.purchaseLocator
    const voucherCode = this.getVoucherCode(purchase)

    const meta = {
      transaction_id: transaction,
      voucher_id: voucherCode,

      coupon: '',
      currency: purchase.pricing.final.currency,
      items,
      shipping_tier: '',
      value: numericPrice,
    }

    this.send('add_shipping_info', meta)
  }

  static sendAddShippingInfoGetAway(purchase: GetAwayPurchase) {
    const rate = GetAwayPurchase.getSelectedRate(purchase)
    if (!rate) {
      return
    }

    const numericPrice = rate.basePriceWithDiscount.amount / 100

    const items = this.mapGetAwayToItems(purchase)

    const transaction = purchase.locators.purchase

    const meta = {
      transaction_id: transaction,

      coupon: '',
      currency: rate.basePriceWithDiscount.currency,
      items,
      shipping_tier: '',
      value: numericPrice,
    }

    this.send('add_shipping_info', meta)
  }

  static sendAddBeginCheckoutVoucher(purchase: VoucherPurchase) {
    const numericPrice = purchase.pricing.final.amount / 100

    const items = this.mapVouchersToItems(purchase)

    const transaction = purchase.locator.purchaseLocator
    const voucherCode = this.getVoucherCode(purchase)

    const meta = {
      transaction_id: transaction,
      voucher_id: voucherCode,

      coupon: '',
      currency: purchase.pricing.final.currency,
      items,
      shipping_tier: '',
      value: numericPrice,
    }

    this.send('begin_checkout', meta)
  }

  static sendAddBeginCheckoutGetAway(purchase: GetAwayPurchase) {
    const rate = GetAwayPurchase.getSelectedRate(purchase)
    if (!rate) {
      return
    }

    const numericPrice = rate.basePriceWithDiscount.amount / 100

    const items = this.mapGetAwayToItems(purchase)

    const transaction = purchase.locators.purchase

    const meta = {
      transaction_id: transaction,

      coupon: '',
      currency: rate.basePriceWithDiscount.currency,
      items,
      shipping_tier: '',
      value: numericPrice,
    }

    this.send('begin_checkout', meta)
  }

  static sendAddPaymentInfoVoucher(purchase: VoucherPurchase) {
    const numericPrice = purchase.pricing.final.amount / 100

    const items = this.mapVouchersToItems(purchase)

    const transaction = purchase.locator.purchaseLocator
    const voucherCode = this.getVoucherCode(purchase)

    const meta = {
      transaction_id: transaction,
      voucher_id: voucherCode,

      coupon: '',
      currency: purchase.pricing.final.currency,
      items,
      payment_type: 'Credit Card',
      value: numericPrice,
    }

    this.send('add_payment_info', meta)
  }

  static sendAddPaymentInfoGetAway(purchase: GetAwayPurchase) {
    const rate = GetAwayPurchase.getSelectedRate(purchase)
    if (!rate) {
      return
    }

    const numericPrice = rate.basePriceWithDiscount.amount / 100

    const items = this.mapGetAwayToItems(purchase)

    const transaction = purchase.locators.purchase

    const meta = {
      transaction_id: transaction,

      coupon: '',
      currency: rate.basePriceWithDiscount.currency,
      items,
      payment_type: 'Credit Card',
      value: numericPrice,
    }

    this.send('add_payment_info', meta)
  }

  static sendPurchaseVoucher(purchase: VoucherPurchase) {
    const numericPrice = purchase.pricing.final.amount / 100

    const items = this.mapVouchersToItems(purchase)

    const transaction = purchase.locator.purchaseLocator
    const voucherCode = this.getVoucherCode(purchase)

    const meta = {
      voucher_id: voucherCode,

      transaction_id: transaction,
      affiliation: 'Spalopia',
      value: numericPrice,
      tax: 0,
      shipping: 0,
      currency: purchase.pricing.final.currency,
      coupon: '',
      items,
    }

    this.send('purchase', meta)
  }

  static sendPurchaseGetAway(purchase: GetAwayPurchase) {
    const rate = GetAwayPurchase.getSelectedRate(purchase)
    if (!rate) {
      return
    }
    const numericPrice = rate.basePriceWithDiscount.amount / 100

    const items = this.mapGetAwayToItems(purchase)

    const transaction = purchase.locators.purchase

    const meta = {
      transaction_id: transaction,
      affiliation: 'Spalopia',
      value: numericPrice,
      tax: 0,
      shipping: 0,
      currency: rate.basePriceWithDiscount.currency,
      coupon: '',
      items,
    }

    this.send('purchase', meta)
  }

  static sendPurchaseVoucherFailure(purchase: VoucherPurchase) {
    const numericPrice = purchase.pricing.final.amount / 100

    const items = this.mapVouchersToItems(purchase)

    const transaction = purchase.locator.purchaseLocator
    const voucherCode = this.getVoucherCode(purchase)

    const meta = {
      voucher_id: voucherCode,

      transaction_id: transaction,
      affiliation: 'Spalopia',
      value: numericPrice,
      tax: 0,
      shipping: 0,
      currency: purchase.pricing.final.currency,
      coupon: '',
      items,
    }

    this.send('purchase_failure', meta)
  }

  static sendPurchaseGetAwayFailure(purchase: GetAwayPurchase) {
    const rate = GetAwayPurchase.getSelectedRate(purchase)
    if (!rate) {
      return
    }

    const numericPrice = rate.basePriceWithDiscount.amount / 100

    const items = this.mapGetAwayToItems(purchase)

    const transaction = purchase.locators.purchase

    const meta = {
      transaction_id: transaction,
      affiliation: 'Spalopia',
      value: numericPrice,
      tax: 0,
      shipping: 0,
      currency: rate.basePriceWithDiscount.currency,
      coupon: '',
      items,
    }

    this.send('purchase_failure', meta)
  }

  static getVoucherCode(purchase: VoucherPurchase): string {
    if (purchase.locator.voucherCode) {
      return purchase.locator.voucherCode
    }

    return ''
  }

  static mapService(service: Service, spa: Spa) {
    const price = (service.price.amount / 100)

    const meta = {
      currency: service.price.currency,
      items: [
        {
          item_id: service.uuid,
          item_name: spa.name,
          coupon: '',
          discount: 0,
          item_brand: 'Spalopia',
          item_variant: '',
          promotion_id: '',
          promotion_name: '',
          item_category: translate(service.texts).name,
          item_category2: 'bono',
          item_category3: 'spalopia',
          item_category4: 'spa y masaje',
          affiliation: 'Spalopia',
          price,
          currency: service.price.currency,
          quantity: 1,
        },
      ],
      value: price,
    }

    return meta
  }

  static mapVouchersToItems(purchase: VoucherPurchase) {
    const [voucher] = purchase.vouchers
    const numericFinalPrice = purchase.pricing.final.amount
    const numericBasePrice = purchase.pricing.base.amount
    const discountCents = numericBasePrice - numericFinalPrice

    const items = voucher.items.map((voItem) => {
      const price = Math.floor(numericBasePrice / voItem.units) / 100
      const discount = Math.floor(discountCents / voItem.units) / 100

      const coupon = purchase.pricing.coupons.map((c) => c.alias).join(', ')

      return {
        item_id: voItem.uuid,
        item_name: purchase.spa.name,
        coupon,
        price,
        currency: purchase.pricing.final.currency,
        discount,
        quantity: voItem.units,
        promotion_id: '',
        promotion_name: '',
        item_category: voItem.name,
        item_category2: this.getVoucherCode(purchase), // bono
        item_category3: 'bono',                    // spalopia
        item_category4: 'spalopia',                // spa y masaje
        affiliation: 'Spalopia',
        item_brand: 'UUID: ' + voItem.uuid,
        item_variant: 'UUID-Revision: ' + voItem.uuidRevision,
      }
    })

    return items
  }

  static mapGetAwayToItems(purchase: GetAwayPurchase) {
    const itemCategory = translate(purchase.getAwayAvailability.getAway.texts).name

    const rate = GetAwayPurchase.getSelectedRate(purchase)
    if (!rate) {
      return
    }

    const numericFinalPrice = rate.basePriceWithDiscount.amount
    const numericBasePrice = rate.basePrice.amount
    const discountCents = numericBasePrice - numericFinalPrice

    const spa = purchase.getAwayAvailability.getAway.spa.name
    const hotel = purchase.getAwayAvailability.getAway.hotel.name

    const itemName = `${hotel}, ${spa}`

    const price = numericBasePrice / 100
    const discount = discountCents / 100

    const item = {
      item_id: purchase.uuid,
      item_name: itemName,
      coupon: '',
      price,
      currency: rate.basePrice.currency,
      discount,
      quantity: purchase.getAwayAvailability.totalNights,
      promotion_id: '',
      promotion_name: '',
      item_category: itemCategory,
      item_category2: 'escapada',
      item_category3: 'escapada',                    // spalopia
      item_category4: 'spalopia',                    // spa y masaje
      affiliation: 'Spalopia',
      item_brand: 'UUID: ' + purchase.uuid,
      item_variant: 'UUID-Revision: ' + purchase.uuidRevision,
    }

    return [ item ]
  }
}
