<template>
  <VoucherCheckoutWrapper
    :voucher-purchase="voucherPurchase"
    :step-index="1"
  >
    <div class="voucher-purchase-wrapper">
      <div
        v-if="isReady && voucherPurchase"
        class="voucher-purchase-payment"
      >
        <div
          v-if="!appliedCoupon"
          class="coupon-form-toggle"
        >
          <h3 class="coupon-form-toggle__header-title">
            <DynLang
              by-key="i-have-discount-coupon"
              capitalize-first
            />
          </h3>
          <SplToggleSwitch
            :value="showCouponInput"
            @input="setShowCouponInput(!showCouponInput)"
          />
        </div>

        <div
          v-else
          class="coupon-form-with-code"
        >
          <div class="coupon-form-with-code__label">
            <DynLang
              by-key="coupon-XX-applied"
              :params="{label: appliedCoupon.alias}"
              capitalize-first
            />
          </div>
        </div>

        <div v-if="showCouponInput">
          <div class="coupon-form-body">
            <div class="coupon-form-body__input">
              <SplInputBox
                id="coupon-code"
                v-model="couponCode"
                label-translation-key="code"
                variant="base"
                type="text"
                :has-border="true"
                :is-valid="!couponCodeError"
                :max-length="32"
                :disabled="!!appliedCoupon"
                @input="resetCouponValidation"
              />
            </div>

            <div v-if="!!appliedCoupon">
              <SplButton
                class="coupon-form-body__btn"
                variant="acent-green"
                size="sm"
                @click="onCouponRemoveClick"
              >
                <DynLang
                  by-key="remove"
                  capitalize-first
                />
              </SplButton>
            </div>
            <div v-else>
              <SplButton
                class="coupon-form-body__btn"
                variant="acent-green"
                size="sm"
                @click="onCouponSendClick"
              >
                <DynLang
                  by-key="apply"
                  capitalize-first
                />
              </SplButton>
            </div>
          </div>

          <div
            v-if="couponCodeSuccess"
            class="coupon-form-success"
          >
            <DynLang
              by-key="coupon-XX-applied-with-XX-value"
              :params="{label: couponCode, discount: currentCouponDiscount}"
            />
          </div>

          <div
            v-if="couponCodeError"
            class="coupon-form-error"
          >
            <div v-if="couponCode.length">
              {{ couponCodeErrorMessage }}
            </div>
          </div>
        </div>
      </div>

      <div class="voucher-purchase-payment">
        <div class="options">
          <h3 class="header">
            <DynLang
              by-key="payment-method"
              capitalize-first
            />
          </h3>
          <h4 class="subheader">
            <DynLang
              by-key="all-transtactions-are-safe"
              capitalize-first
            />
          </h4>

          <Badge
            color="secondary"
            class="badge"
          >
            <DynLang
              v-if="isChrismas"
              by-key="summary-change-refund-chrst"
              capitalize-first
            />
            <DynLang
              v-else
              by-key="summary-change-refund"
              capitalize-first
            />
          </Badge>

          <div class="payment-selector">
            <ul class="payment-method-list">
              <li
                v-for="(paymentMethod, index) in paymentMethodCollection"
                :key="index"
                class="payment-method-list-item"
              >
                <SplRadioButton
                  id="payment-method-radio"
                  border-less
                  :label="paymentMethod.name"
                  :label-translation-key="paymentMethod.nameLangKey"
                  name="desktopPaymentOptions"
                  :value="getIsSelected(paymentMethod)"
                  @input="handlePaymentInput(paymentMethod)"
                >
                  <template #extra>
                    <PaymentMethodImages :payment-method-type="paymentMethod.type" />
                  </template>
                </SplRadioButton>
              </li>
            </ul>
          </div>
        </div>

        <div
          v-show="isMinusOneError"
          class="redsys-payment-wrapper__token-error"
        >
          <p>
            Ha habido algún error con los datos de la tarjeta, por favor, inténtelo de nuevo.
          </p>
          <p>
            Si este mensaje continúa apareciendo,
            <SplNuxtLink
              reload-on-click
              :to="currentUrl"
              class="redsys-payment-wrapper__token-error__reload-link"
            >
              pulse aquí.
            </SplNuxtLink>
          </p>
        </div>

        <div v-if="isRedsysPaymentShown && fuc">
          <client-only>
            <RedsysPayment
              v-if="isReady && voucherPurchase"
              :voucher-order-i-d="voucherOrderID"
              :purchase-uuid="voucherPurchase.uuid"
              :fuc="fuc"
              @on-token-received="onToken"
            />
          </client-only>
        </div>

        <div>
          <BenefitsCard
            :tags="['SSL', 'SAFE_PAYMENT_ENSURED']"
            :can-close="false"
            :is-title-displayed="false"
          />
        </div>
      </div>

      <div>
        <div class="voucher-purchase-payment-buttons">
          <SplNuxtLink
            :to="backUrl"
            is-external
            class="form-btns-back"
          >
            <DynLang
              by-key="back"
              capitalize-all
            />
          </SplNuxtLink>
        </div>
      </div>
    </div>
  </VoucherCheckoutWrapper>
</template>

<script lang="ts">
import VoucherCheckoutWrapper from '~/core/components/voucher-purchase/VoucherCheckoutWrapper.vue'
import RedsysPayment from '~/core/components/voucher-purchase/RedsysPayment.vue'
import PaymentMethodImages from '~/core/components/voucher-purchase/PaymentMethodImages.vue'
import SplToggleSwitch from '~/core/components/shared/form/SplToggleSwitch.vue'

import BenefitsCard from '~/core/components/spa/BenefitsCard.vue'
import Badge from '~/core/components/shared/Badge.vue'
import SplNuxtLink from '~/core/components/shared/SplNuxtLink.vue'
import SplInputBox from '~/core/components/shared/form/SplInputBox.vue'
import SplButton from '~/core/components/shared/form/SplButton.vue'
import SplRadioButton from '~/core/components/shared/form/SplRadioButton.vue'

import { useVoucherPaymentForm } from '~/core/composable/voucher-purchase/useVoucherPaymentForm'

import config from '~/core/ts/server-config'

import { type PaymentMethod } from '~/core/ts/entity/payment-method/PaymentMethod'

definePageMeta({
  layout: 'checkout',
})

export default defineComponent({
  components: {
    VoucherCheckoutWrapper,
    RedsysPayment,
    SplToggleSwitch,
    PaymentMethodImages,
    BenefitsCard,
    Badge,
    SplNuxtLink,
    SplInputBox,
    SplButton,
    SplRadioButton,
  },
  emits: [
    'reload'
  ],
  setup(_props, { emit }) {
    const {
      voucherUUID,

      isReady,
      fuc,
      voucherOrderID,
      voucherPurchase,
      generateVoucherOrderID,
      paymentMethodCollection,
      paymentMethodSelected,
      setPaymentMethodSelected,
      // INSITE
      prepareAndAuthorizePayment,

      // Cupones
      couponCode,
      couponCodeSuccess,
      couponCodeError,
      couponCodeErrorMessage,
      resetCouponValidation,
      applyCouponToVoucherPurchase,
      removeCouponFromVoucherPurchase,
      showCouponInput,
      setShowCouponInput,
      appliedCoupon,
      currentCouponDiscount,
    } = useVoucherPaymentForm()

    const route = useRoute()
    const currentUrl = route.path

    const tokenElementSingle = ref<HTMLInputElement | null>(null)
    const errorElementSingle = ref<HTMLInputElement | null>(null)

    const backUrl = computed(() => {
      if (!voucherUUID) {
        return ''
      }

      return `/voucher/${voucherUUID}/checkout/buyer`
    })

    const getIsSelected = (paymentMethod: PaymentMethod) => {
      return paymentMethod.type === paymentMethodSelected.value?.type
    }

    const handlePaymentInput = (paymentMethod: PaymentMethod) => {
      setPaymentMethodSelected(paymentMethod)
    }

    const isPaymentDisabled = computed(() => {
      return !paymentMethodSelected.value
    })

    const isMinusOneError = ref(false)
    const isRedsysPaymentShown = ref(true)

    const onTokenError = async () => {
      isMinusOneError.value = true

      // Reset Redsys form status
      isRedsysPaymentShown.value = false
      generateVoucherOrderID()
      setTimeout(() => {
        isRedsysPaymentShown.value = true
      }, 1500)
    }

    const onToken = async (token: string) => {
      isMinusOneError.value = false

      if (!token) {
        return
      }

      if (token === '-1') {
        onTokenError()
        return
      }

      await prepareAndAuthorizePayment(voucherOrderID.value, token)
    }

    const onCouponSendClick = async () => {
      const success = await applyCouponToVoucherPurchase()
      if (success) {
        emit('reload')
      }
    }

    const onCouponRemoveClick = async () => {
      const success = await removeCouponFromVoucherPurchase()
      if (success) {
        emit('reload')
      }
    }

    const isChrismas = config.promos.isChrismas

    return {
      isReady,
      fuc,
      voucherPurchase,
      paymentMethodCollection,
      paymentMethodSelected,
      handlePaymentInput,
      isPaymentDisabled,
      getIsSelected,

      currentUrl,
      backUrl,

      voucherOrderID,
      onToken,
      isMinusOneError,
      isRedsysPaymentShown,
      tokenElementSingle,
      errorElementSingle,

      // cupones
      couponCode,
      couponCodeSuccess,
      couponCodeError,
      couponCodeErrorMessage,
      currentCouponDiscount,
      resetCouponValidation,
      onCouponSendClick,
      onCouponRemoveClick,
      showCouponInput,
      setShowCouponInput,
      appliedCoupon,

      isChrismas,
    }
  },
})
</script>

<style lang="scss" scoped>
.voucher-purchase-wrapper {
  @apply mb-6;
}

.redsys-payment-wrapper {
  @apply bg-white;

  @apply mb-2;
  @apply pb-30p;

  &__token-error {
    @apply text-red-900;
    @apply my-4;
    @apply mx-2;

    &__reload-link {
      @apply underline;
      @apply text-spl-gray-3;
    }
  }
}

.voucher-purchase-payment {
  @apply bg-white;

  @apply mb-2;
  @apply py-6;
  @apply px-2;

  @screen lg {
    @apply px-6;
  }

  .header {
    @apply text-xl;
    @apply font-bold;
    @apply text-spl-dark;
    @apply my-20p;
  }

  .subheader {
    @apply italic;
    @apply text-spl-gray-4;
    @apply my-5p;
    @apply text-base;
  }

  .badge {
    @apply my-10p;
  }

  .payment-selector {
    @apply mt-6;
    @apply py-4;


    @apply border-solid;
    @apply border-t-2;
  }
}

.voucher-purchase-payment-buttons {
  @apply mt-2;

  @apply flex;
  @apply justify-center;
  @apply items-center;
  @apply p-3;

  @screen lg {
    @apply justify-end;
  }

  .form-btns-back {
    @apply underline;
  }
}

.coupon-form-toggle {
  @apply flex;
  @apply justify-between;
  @apply items-center;

  span {
    @apply text-spl-dark;
    @apply font-semibold;
  }

  &__header-title {
    @apply font-bold;
    @apply text-spl-dark;
  }
}
.coupon-form-toggle.floating {
  float: right;
}

.coupon-form-body {
  @apply mt-2;

  @apply flex;
  @apply flex-col;
  @apply items-center;
  @apply gap-1;

  @screen lg {
    @apply flex-row;
    @apply justify-between;
  }

  &__input {
    @apply flex-grow;
    @apply mr-2;

    @apply my-2;

    @apply w-full;
    @apply border-1;
  }

  &__btn {
    @apply w-full;
  }
}

.coupon-form-with-code {
  @apply flex justify-between items-center;

  &__label {
    @apply text-spl-primary-dark;
    @apply font-semibold;
  }
}

.coupon-form-success {
  @apply m-2;

  @apply text-green-700;
}

.coupon-form-error {
  @apply m-2;

  @apply text-spl-red;
  @apply rounded-md;
}
</style>
