import { useContext } from './useContext'

import LocationSessionRepository from '~/core/ts/repository/LocationSessionRepository'

import { Location } from '~/core/ts/entity/Location'

export function useSessionLocation(locationCollection: Array<Location> = []) {
  const runtime = useRuntimeConfig()
  const context = useContext()

  const locationsByUUID: Record<string, Location> = {}
  const locations: Array<Location> = locationCollection
  locations.forEach((loc) => { locationsByUUID[loc.uuid] = loc })

  const storedLocationInternal = useState<Location | null>('session-location', () => null)

  const enabled = computed(() => {
    // TODO: DEV. Para activar cuando esté hecho lo de la localización en sesión.
    if (!runtime.public.isDev) {
      return false
    }

    if (context.type.value === 'dynamic') {
      return false
    }

    return true
  })

  const storedLocation = computed(() => {
    if (!enabled.value) {
      return null
    }

    return storedLocationInternal.value
  })

  const language = runtime.public.language

  function storeInSession(loc: Location) {
    if (!enabled) {
      storedLocationInternal.value = null
      return
    }

    if (!locations.length) {
      return
    }

    if (!loc.parentUUID || loc.type === 'community' || loc.type === 'region') {
      LocationSessionRepository.store(language, loc)
      storedLocationInternal.value = loc
      return
    }

    const parent = locationsByUUID[loc.parentUUID]
    if (!parent) {
      storedLocationInternal.value = null
      return
    }

    storeInSession(parent)
  }

  function readFromSession() {
    if (!enabled) {
      storedLocationInternal.value = null
      return
    }

    storedLocationInternal.value = LocationSessionRepository.read(language)
  }

  return {
    enabled,
    storedLocation,

    storeInSession,
    readFromSession,
  }
}
