<template>
  <div />
</template>

<script lang="ts">
import { useFetchProxy } from '~/core/composable/shared/useFetchProxy'
import { Redirection } from '~/core/ts/entity/Redirection'
import { joinPaths } from '~/core/ts/util/string'

definePageMeta({
  layout: 'blank',
})

export default defineNuxtComponent({
  async setup() {
    const route = useRoute()

    const runtime = useRuntimeConfig()
    const domain = runtime.public.domain

    onMounted(() => {
      const meta = document.getElementById('redirector')
      if (meta) {
        var redirectionContent = meta.getAttribute('content')
        if (redirectionContent) {
          var url = redirectionContent.split('url=')[1] || window.location.href
          if (location.search.length) { window.stop() }
          window.location.href = url + location.search
        }
      }
    })

    const uri = route.path
    const redirection = await useFetchProxy<Redirection>('/api/redirections/get-redirection-from-uri',  { method: 'post', body: { uri } })

    let finalurl = redirection.to
    if (runtime.public.shouldPrependLanguajeToURLs) {
      finalurl = joinPaths(runtime.public.language, finalurl)
    }

    finalurl = joinPaths(domain, finalurl)

    const content = `0; url=${finalurl}`
    useHead({
      meta: [{ id: 'redirector', 'http-equiv': 'refresh', content }],
    })

    return {
      redirection
    }
  }
})
</script>
