export default class LocalStorageProxy {
  public static getItem(key: string): string | null {
    try {
      return localStorage.getItem(key)
    } catch {
      console.error('WARN: Can not access localStorage.getItem')
    }

    return null
  }

  public static setItem(key: string, value: string): void {
    try {
      localStorage.setItem(key, value)
    } catch {
      console.error('WARN: Can not access localStorage.setItem')
    }
  }

  public static removeItem(key: string): void {
    try {
      localStorage.removeItem(key)
    } catch {
      console.error('WARN: Can not access localStorage.removeItem')
    }
  }
}
