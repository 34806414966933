const es = {
  singular: {
    'search': 'buscar',
    'filter': 'filtrar',
    'filter-by': 'filtrar por',
    'region': 'región',
    'experience': 'experiencia',
    'found': 'encontrado',
    'sort-by': 'ordenar por',
    'recommended': 'recomendado',
    'most-valued': 'mejor valorado',
    'cheapest-service': 'servicio más barato',
    'most-expensive-service': 'servicio más caro',
    'see': 'ver',
    'see-more': 'ver más',
    'see-less': 'ver menos',
    'see-options': 'ver opciones',
    'see-spas': 'ver spas',
    'XX-in-XX': '{{left}} en {{right}}',
    'coupon': 'cupón',
    'buy': 'comprar',
    'book': 'reservar',
    'continue': 'continuar',
    'back': 'atrás',
    'go-back': 'volver atrás',
    'hotel-kind': 'tipo de hotel',
    'cheapest-hotel': 'hotel más barato',
    'most-expensive-hotel': 'hotel más caro',
    'hotel-and-spa': 'hotel + spa',
    'other': 'otro',
    'remove': 'quitar',
    'apply': 'aplicar',
    'code': 'código',
    'remember-exc': '¡Recuerda!',
    'pay': 'pagar',
    'thanks': 'gracias',
    'here': 'aquí',
    'warning': 'alerta',

    'loc-type-country': 'país',
    'loc-type-community': 'comunidad',
    'loc-type-region': 'provincia',
    'loc-type-city': 'ciudad',

    'gift-voucher': 'bono regalo',
    'show-all-photos': 'mostrar todas las fotos',
    'your-getaway-includes': 'tu escapada incluye',
    'no-availability-for-given-dates-and-pax': 'no tenemos disponibilidad para las fechas y la personas seleccionadas',
    'no-rates-for-given-dates-and-pax': 'no hay tarifas disponibles para esta configuración',
    'discount-for-time': 'descuento por tiempo limitado',
    'night': 'noche',
    'step-personal-data': 'Datos Personales',
    'step-payment-options': 'Opciones de pago',
    'no-refundable': 'no reembolsable',
    'no-refundable-get-away': 'No reembolsable. No se permiten cancelaciones ni cambios de fechas.',
    'no-changes-nor-cancel-allowed': 'No se permiten cancelaciones ni cambios de fechas',
    'refunds-label-date': 'Cancelación gratuita hasta el {{deadline}}, después tendrá un coste de {{price}}',
    'refunds-label-time':'Cancelación gratuita hasta las {{deadline}}, después tendrá un coste de {{price}}',

    'purchase-has-to-accept-conditions': 'Debes aceptar los términos y condiciones para poder continuar con tu proceso',

    'downloading-purchase-data': 'descargando los datos de su compra',
    'downloading-booking-data': 'Descargando los datos de su reserva',
    'confirming-voucher': 'estamos confirmando su bono.',
    'confirming-booking': 'estamos confirmando su reserva.',
    'voucher-confirm-takes-a-minute': 'esto puede llevar unos minutos, sé paciente.',

    'were-sorry': 'Lo sentimos.',
    'an-error-has-ocurred-contact-support': 'Ha ocurrido un error, por favor, póngase en contacto con nuestro servicio técnico',
    'apologise-for-inconvenience': 'Disculpe las molestias.',
    'you-can-try-again-here': 'puede volver a intentar su compra pulsando',

    'an-error-has-ocurred': '¡Ha ocurrido un error!',
    'the-purchase-could-not-be-started': 'No hemos podido inicar el proceso de compra.',
    'please-try-it-again-later': 'Por favor, inténtelo más tarde.',
    'if-problems-persist-contact-support': 'Si sigue teniendo problemas, póngase en contacto con nuestro servicio técnico',

    'booking-cannot-continue': 'No se pudo continuar con la reserva. Inténtelo más tarde',
    'booking-cannot-be-located-support': 'La reserva no pudo ser localizada. Por favor, contacte con nosotros y se lo solucionaremos en seguida',
    'booking-is-already-booked': 'Esta reserva ya ha sido confirmada.',

    'voucher-cannot-continue': 'No se pudo continuar con la compra. Inténtelo más tarde',
    'voucher-cannot-be-located-support': 'La venta no pudo ser localizada. Por favor, contacte con nosotros y se lo solucionaremos en seguida',

    'your-voucher-is-confirmed': 'Su bono ha sido confirmado con éxito',
    'you-will-receive-voucher-email': 'En breve recibirás el bono y el teléfono del spa para reservar cita en la dirección',
    'remember-voucher-at-spa': 'Recuerda que es necesario presentarlo en formato físico o digital.',
    'contact-support-with-any-question': 'Si tienes alguna pregunta, no dudes en contactarnos al email',
    'booking-get-your-bags-ready': 'Prepara las maletas porque vas a disfrutar de unos días increíbles en',
    'congratulations-XX': '¡Felicidades {{client}}!',

    'payment-method': 'método de pago',
    'credit-card-payment': 'Pagar con tarjeta de crédito',
    'all-transtactions-are-safe': 'Todas las transacciones hechas en este sitio son seguras',

    'name': 'nombre',
    'surname': 'apellidos',
    'email': 'email',
    'confirm-email': 'confirmar email',
    'phone': 'teléfono',
    'name-and-surname': 'nombre y apellidos',

    'offer-accept-label': 'Acepto recibir información y ofertas en mi email.',

    'ive-read-and-accept': 'He leído y acepto los ',
    'and the': ' y los ',

    'service-terms-and-conditions': 'términos y condiciones del servicio',
    'terms-and-conditions': 'términos y condiciones',
    'hotel-spa-terms-and-conditions': 'Términos y condiciones del Hotel y Spa',

    'buyer-form-name-and-surname-error': 'El nombre y apellidos no son correctos',
    'buyer-form-name-error': 'El Nombre no es correcto ',
    'buyer-form-surname-error': 'Los Apellidos no son correctos ',
    'buyer-form-email-error': 'El Email no es correcto',
    'buyer-form-phone-error': 'El Número de teléfono no es correcto',
    'buyer-form-terms-error': 'Ha de aceptar los términos y condiciones',

    'if-thereis-coupon-applies-next-step': 'Si tiene un código de descuento se aplica en el siguiente paso.',

    'i-have-discount-coupon': 'Tengo un código de descuento',
    'coupon-XX-applied': 'Cupón "{{label}}" aplicado',
    'coupon-XX-applied-with-XX-value': '¡Código "{{label}}" con descuento del {{discount}}% aplicado correctamente!',

    // Se usan para traducir los horarios del spa.
    'lunes': 'lunes',
    'martes': 'martes',
    'miercoles': 'miércoles',
    'jueves': 'jueves',
    'viernes': 'viernes',
    'sabado': 'sábado',
    'domingo': 'domingo',
    'festivo': 'festivo',

    'special-conditions': 'Condiciones especiales',

    'hotels-with-spa': 'hoteles con spa',

    'from-value': 'a partir de',

    'select-price': 'selecciona un importe',

    'minute': 'minuto',

    'environment': 'ambiente',
    'service': 'servicio',
    'attention': 'atención',
    'quality-price': 'calidad-precio',
    'comment': 'comentario',

    'the-spa': 'el spa',
    'the-hotel': 'el hotel',

    'you-will-find-in-XX': 'Vas a encontrar en {{spaName}}',

    'send': 'enviar',
    'sent-comment': 'comentario enviado',

    'reviews-last': 'Últimas valoraciones',
    'reviews-add': 'Agregar valoración',
    'reviews-empty': 'No hay valoraciones recientes',

    'description': 'descripción',

    'unique-benefits': 'Beneficios únicos',

    'enjoy-once-is-open': 'disfrútalo cuando reabra',
    'enjoy-once-spa-is-open': 'disfrútalo cuando reabra el spa',
    'couple': 'pareja',
    'couple-special': 'especial pareja',
    'included-in-service': 'este servicio incluye',
    'included-in-XX-min-service': 'este servicio de {{time}} mins incluye',

    'is-gift-voucher-available': 'bonos regalo disponibles',
    'available-service': 'servicio disponible',
    'available-service-in-category': 'servicio disponible en categoría',
    'service-for': 'servicio para',
    'service-duration': 'duración del servicio',

    'spa-status-online': 'Spa Abierto',
    'spa-status-disabled': 'Spa desactivado',
    'spa-status-closed': 'Spa cerrado',
    'spa-status-closedtemp': 'Spa cerrado por temporada',
    'spa-status-lost': 'Spa perdido',

    'no-available-services': 'no se econtraron Servicios disponibles',
    'no-available-services-secondary': 'en este momento no podemos ofrecerte ningún servicio con los filtros seleccionados',

    'no-available-treatments': 'no se econtraron Tratamientos disponibles',
    'no-available-treatments-secondary': 'en este momento no podemos ofrecerte ningún tratamiento con los filtros seleccionados.',

    'no-available-promotions': 'No se econtraron Promociones Disponibles',
    'no-available-promotions-secondary': 'En este momento no podemos ofrecerte ninguna promoción con los filtros seleccionados.',

    'no-available-spas': 'No se encontraron Spas Disponibles',
    'no-available-spas-secondary': 'En este momento no podemos ofrecerte ningún spa con los filtros seleccionados.',

    'no-available-get-away': 'No se econtraron Escapadas Disponibles',
    'no-available-get-away-secondary': 'En este momento no podemos ofrecerte ninguna Escapada con los filtros seleccionados.',

    'no-available-get-away-availability': 'No se encontraron Escapadas disponibles para las fechas:',
    'no-available-get-away-availability-secondary': 'Por favor, seleccione otras fechas',

    'spa-schedule': 'Horarios del spa',

    'services-and-treatments': 'servicios y tratamientos',

    'summary-spa-closedtemp': 'Spa cerrado por temporada. Disfruta del bono cuando reabra el spa.',
    'summary-voucher-pdf': 'Recibes el bono en PDF y el teléfono para reservar cita.',
    'summary-change-refund': 'Cambios y devoluciones hasta 14 días después de la compra.',
    'summary-change-refund-chrst': 'Cambios y devoluciones hasta el 20 de enero.',
    'summary-expiration': 'Si caduca, cambiamos por tarjeta regalo de igual valor.',
    'summary-service-unavailable': 'Servicio no disponible',
    'summary-voucher-expirate-month': 'Canjeable durante los próximos {{months}} meses.',
    'summary-voucher-expirate-day': 'Canjeable durante los próximos {{days}} días.',

    'footer-company-code': 'Le comunicamos que el código de identificación asignado a dicha actividad es el',
    'contact-us': '¡Contáctanos!',
    'spa-gift-voucher': 'Bonos Regalos de Spa',
    'stay': 'estancia',
    'receive-offers': '¡Recibe las mejores oportunidades para su descanso en tu Email!',
    'search-spas': 'Buscar spas',

    'only-hotel': 'solo hotel',
    'person': 'persona',
    'destination': 'Destino',
    'departure': 'llegada',
    'arrive': 'salida',
    'treatment': 'tratamiento',
    'promotion': 'promoción',
    'massage': 'masaje',
    'hydrotherapy': 'hidroterapia',
    'ritual': 'ritual',
    'food': 'comida',
    'hotel': 'hotel',
    'offer': 'oferta',
    'booking': 'reserva',
    'online-booking': 'reserva online',

    'booking-for': 'reserva para {{label}}',

    'treatment-in': 'tratamiento en {{location}}',

    'get-away-if-you-want-more-people': 'Si quieres buscar más de 2 personas, puedes usar el buscador general o entrar al detalle de una escapada',
    'get-away-you-can-modify': 'Puedes personalizar tu Escapada',
    'get-away-price-for': 'Precios para las fechas',
    'get-away-no-availability-for-dates': 'No hay escapadas disponibles para las fechas',
    'search-other-date': 'Buscar otra fecha',
    'search-by-date': 'Buscar por fecha',
    'search-for-two': 'Búsqueda para 2 personas',

    'escapada': 'escapada',
    'spa': 'spa',

    'are-you-spa': '¿Eres un spa?',

    'mothers-day': 'Día de la Madre',

    'gift-card': 'Tarjetas Regalo',
    'gift-card-1': 'Bienestar de ensueño 25€',
    'gift-card-2': 'Relax sublime 50€',
    'gift-card-3': 'Oasis Deluxe 80€',
    'gift-card-4': 'Experiencia Sensorial 100€',
    'gift-card-5': 'Capricho Eterno 120€',

    'special-moments': 'Momentos Especiales',
    'special-moments-1': 'Circuito Spa',
    'special-moments-2': 'Spa en Pareja',
    'special-moments-3': 'Spa & Gastro',
    'special-moments-4': 'Circuito Spa & Masaje',
    'special-moments-5': 'Circuito Spa & Ritual',
    'special-moments-6': 'Spa Privado',
    'special-moments-7': 'Spa para Niños',
    'special-moments-8': 'Daypass en Spa',
    'special-moments-9': 'Spa para embarazadas',

    'treatment-1': 'Tratamientos faciales',
    'treatment-2': 'Tratamientos corporales',
    'treatment-3': 'Rituales de Belleza',

    'about-us': 'Sobre Nosotros',
    'about-us-1': 'Quienes somos',
    'about-us-2': 'Aviso legal',
    'about-us-3': 'Política de Privacidad',
    'about-us-4': 'Política de Cambios y Devoluciones',
    'about-us-5': 'Condiciones Generales de Compra',
    'about-us-6': 'Política de cookies',
    'about-us-7': 'Blog',
    'about-us-8': 'Contacto',

    'redeem-voucher': 'Canjea tu Bono',

    'relax': 'relaja',
    'connect': 'conecta',
    'share': 'comparte',

    'checkout-voucher-header-title': '¿Listo para disfrutar del Bienestar?',
    'checkout-getaway-header-title': '¿Listo para disfrutar tu Viaje?',

    'checkout-voucher-form-title': 'Completa datos de la compra',
    'checkout-getaway-form-title': 'Completa datos de la reserva',
    'checkout-getaway-gift-title': '¿Es para regalo?',
    'checkout-getaway-gift-form-header':'¿Para quién es el Bono?',

    'search-by-location-or-spa': 'Busca por Nombre de Ciudad o del Spa',
    'search-by-destination': 'Buscar por destino',
    'search-by-treatment': 'Busca por Tratamiento',

    'benefits-givings': 'El mejor regalo para el descanso y la salud',
    'benefits-givings-small': 'El mejor regalo',
    'benefits-best-price': 'El precio garantizado',
    'benefits-safe-payment': 'Pago seguro',
    'benefits-save-time': 'Compra ahorrando tiempo',
    'benefits-give-experiencies': 'Regala experiencias',
    'benefits-valid-bonuses': 'Bonos siempre válidos',
    'benefits-support': '¿Tienes alguna duda?',
    'benefits-ssl': 'Encriptación SSL Protegida',
    'benefits-safe-payment-ensured': 'Pago seguro Garantizado',

    'benefits-desc-save-time': 'Sin colas ni esperas en centros Comerciales',
    'benefits-desc-give-experiencies': 'Los objetos acaban en el olvido, las experiencias son para siempre',
    'benefits-desc-valid-bonuses': 'Si caducan te lo cambiamos por un servicio de igual valor que tu elijas',
    'benefits-desc-support': 'Escríbenos a ayuda@spalopia.com',

    'promotion-home-desc': 'Encuentra las mejores experiencias de Spa en España: Circuito Spa, Spa y Masaje, Spa y Brunch, Spa y Hotel... ¡Todo lo que necesitas para descansar!',

    'home-header-title': 'ENCUENTRA LOS MEJORES BALNEARIOS Y SPAS',
    'home-header-subtitle': 'Busca experiencias de relax y bienestar para disfrutar con tus seres queridos en más de 300 spas de toda España',

    'home-promo-title': 'EVITA LA VUELTA A LA RUTINA',
    'home-promo-subtitle': '¡Aprovecha para relajarte con un Day Pass antes de que cambie el tiempo!',
    'home-promo-extra-1': 'Pasa el día completo en las instalaciones del Hotel',

    'home-promo-btn-label': 'Ver opciones Day Pass',

    'home-promo-side-title': 'Disfruta de las instalaciones del Hotel y del Spa sin alojarte',
    'home-promo-side-subtitle': '¡Día completo de relax!',

    'home-promo-section-1-title': 'Los mejores momentos para regalar y disfrutar',
    'home-promo-section-1-text': 'En Spalopia hemos hecho una selección de momentos especiales en el Spa para que puedas regalarlo o disfrutarlo y volver relajarte como te mereces.',
    'home-promo-section-2-title': 'Los mejores Tratamientos para ti',
    'home-promo-section-2-text': '¿Sabías que en el Spa hay mucho más que circuitos termales? Hemos seleccionado los mejores tratamientos para que disfrutes, desconectes, te relajes y recuperes energías con un masaje a 4 manos, una chocolaterapia o una envoltura de algas.',
    'home-promo-section-3-title': 'Momentos únicos para ti y los tuyos',
    'home-promo-section-3-text': 'Hay momentos que se merecen un ratito de relajación y bienestar. Por ello hemos realizado una selección de momentos únicos que te proponemos para que te dediques un ratito especial para ti y tus seres queridos.',
    'home-promo-section-4-title': 'Experiencias inolvidables en los mejores Spa',
    'home-promo-section-4-text': '¿Sabías que en Spalopia tienes más de 300 spas para disfrutar en toda España? Aquí tienes una selección de las provincias e islas favoritas de nuestros clientes para ir al Spa.',
    'home-promo-section-5-text': '¿Quiere disfrutar de una experiencia única de bienestar y relajación en los balnearios de Portugal? He aquí una selección de las ciudades y barrios favoritos de nuestros clientes para ir de balneario en Portugal.',

    'get-away-landing-description-1': 'Descubre las mejores Escapadas románticas en hoteles con spas {{location}}. Tenemos una amplia selección de Escapadas para disfrutar y desconectar en pareja.',
    'get-away-landing-description-2': 'Realiza tu reserva online al mejor precio y vive una experiencia wellness única.',
    'get-away-landing-description-3': 'Hemos seleccionado para ti las mejores Escapadas {{location}} con servicios de Spa, circuito wellness, masajes y rituales de belleza incluidos. Queremos que encuentres la calma y armonía que tanto necesitas junto a tu pareja. Descubre un mundo de sensaciones nuevas en estos completos oasis de bienestar.',
    'get-away-landing-description-4': 'Experiencias románticas en pareja {{location}}. Hoteles de 4* y 5* con media pensión, masajes relajantes, jacuzzis de burbujas, circuitos termales de chorros que les dejarán como nuevos, jacuzzis de burbujas, saunas y baños turco para eliminar toxinas del organismo y fabulosos tratamientos faciales y corporales para limpiar e hidratar la piel al máximo.',
    'get-away-landing-description-5': 'Elige la mejor escapada en pareja en los mejores hoteles con Spa {{location}} y disfruta de la experiencia para desconectar al máximo.',

    'footer-description': 'En Spalopia encontrarás los mejores precios para disfrutar de una experiencia inolvidable en el Spa y centros wellness. Masajes, Rituales, Chocolaterapias, Dayspas, Spa con estancia o Spa con Brunch son algunos de los servicios y tratamientos que podrás seleccionar en los más de 300 spas y centros que trabajan con nosotros alrededor de toda España. Si ya sabes cuando quieres ir al Spa, puedes reservar directamente la fecha y la hora que más te interesa para planificar tu bienestar de una manera sencilla y cómoda con Spalopia. Tenemos miles de ofertas y promociones de Spa para ti y contamos con una gran variedad de servicios disponibles para regalar a tu ser querido a un precio único. Todos nuestros bonos y reservas de tratamientos de spas tienen la seguridad y confianza respaldada por Spalopia, la única web en toda España que tiene el objetivo de mejorar tu bienestar en el Spa.',
  },
  plural: {
    'hotel': 'hoteles',
    'ritual': 'rituales',
    'coupon': 'cupones',

    'description': 'descripciones',

    'couple-special': 'especial parejas',
    'available-service': 'servicios disponibles',
    'available-service-in-category': 'servicios disponibles en categorías',
    'service-for': 'servicios para',

    'treatment-in': 'tratamientos en {{location}}',

    'promotion': 'promociones',

    'recommended': 'recomendados',
  },
}

export default es
