<template>
  <GetAwayCheckoutWrapper
    v-if="getAwayPurchase"
    :step-index="1"
    :get-away-purchase="getAwayPurchase"
  >
    <div class="form-wrapper">
      <div class="form-box">
        <div class="header">
          <DynLang
            by-key="payment-method"
            capitalize-first
          />
        </div>

        <h4 class="subheader">
          <DynLang
            by-key="all-transtactions-are-safe"
            capitalize-first
          />
        </h4>

        <Badge
          color="secondary"
          class="badge"
        >
          <DynLang by-key="remember-exc" />:

          <DynLang
            v-if="isRefundable"
            by-key="refunds-label-time"
            :params="{
              deadline: refundDeadlineDateWithTimeStr,
              price: refundDeadlinePriceStr,
            }"
          />
          <DynLang
            v-else
            by-key="no-refundable-get-away"
          />
        </Badge>

        <div class="payment-selector">
          <ul class="payment-method-list">
            <li
              v-for="(paymentMethod, index) in paymentMethodCollection"
              :key="index"
              class="payment-method-list-item"
            >
              <SplRadioButton
                id="payment-method-radio"
                border-less
                :label="paymentMethod.name"
                :label-translation-key="paymentMethod.nameLangKey"
                name="desktopPaymentOptions"
                :value="getIsPaymentMethodSelected(paymentMethod)"
                @input="handlePaymentMethodInput(paymentMethod)"
              >
                <template #extra>
                  <PaymentMethodImages :payment-method-type="paymentMethod.type" />
                </template>
              </SplRadioButton>
            </li>
          </ul>
        </div>
      </div>

      <div>
        <div class="form-btns">
          <div>
            <SplNuxtLink
              is-external
              :to="backUrl"
              class="form-btns-back"
            >
              <DynLang
                by-key="back"
                capitalize-first
              />
            </SplNuxtLink>
          </div>

          <div>
            <SplButton
              class="form-btns-contlarge"
              type="submit"
              variant="acent"
              size="lg"
              :disabled="!hasPaymentMethodSelected"
              @click="preparePayment"
            >
              <DynLang
                by-key="pay"
                capitalize-first
              />
            </SplButton>
            <SplButton
              class="form-btns-contsmall"
              type="submit"
              variant="acent"
              size="sm"
              :disabled="!hasPaymentMethodSelected"
              @click="preparePayment"
            >
              <DynLang
                by-key="pay"
                capitalize-first
              />
            </SplButton>
          </div>
        </div>
      </div>
    </div>
  </GetAwayCheckoutWrapper>
</template>

<script lang="ts">
import GetAwayCheckoutWrapper from '~/core/components/get-away/GetAwayCheckoutWrapper.vue'
import PaymentMethodImages from '~/core/components/voucher-purchase/PaymentMethodImages.vue'
import SplRadioButton from '~/core/components/shared/form/SplRadioButton.vue'
import Badge from '~/core/components/shared/Badge.vue'
import SplNuxtLink from '~/core/components/shared/SplNuxtLink.vue'
import SplButton from '~/core/components/shared/form/SplButton.vue'

import { useGetAwayPurchasePayment } from '~/core/composable/get-away/useGetAwayPurchasePayment'
import { useNavPopStateReload } from '~/core/composable/shared/useNavPopStateReload'

import type { PaymentMethod } from '~/core/ts/entity/payment-method/PaymentMethod'
import { GetAwayRate } from '~/core/ts/entity/GetAwayAvailability'
import { GetAwayPurchase } from '~/core/ts/entity/GetAwayPurchase'
import { dateToDDMMYYYWithTime } from '~/core/ts/util/date'
import { Money } from '~/core/ts/entity/Money'

definePageMeta({
  layout: 'checkout',
})

export default defineNuxtComponent({
  components: {
    GetAwayCheckoutWrapper,
    PaymentMethodImages,
    SplRadioButton,
    Badge,
    SplNuxtLink,
    SplButton,
  },
  setup() {
    const {
      getAwayPurchase,
      paymentMethodSelected,
      paymentMethodCollection,
      hasPaymentMethodSelected,
      preparePayment,
    } = useGetAwayPurchasePayment()

    function getIsPaymentMethodSelected(paymentMethod: PaymentMethod) {
      return paymentMethod.type === paymentMethodSelected.value?.type
    }

    function handlePaymentMethodInput(paymentMethod: PaymentMethod) {
      paymentMethodSelected.value = paymentMethod
    }

    const isRefundable = computed(() => {
      if (!getAwayPurchase.value) {
        return false
      }

      const selectedRate = GetAwayPurchase.getSelectedRate(getAwayPurchase.value)
      if (!selectedRate) {
        return false
      }

      return GetAwayRate.isRefundable(selectedRate)
    })

    const refundDeadlineDateWithTimeStr = computed(() => {
      if (!getAwayPurchase.value) {
        return ''
      }

      const selectedRate = GetAwayPurchase.getSelectedRate(getAwayPurchase.value)
      if (!selectedRate) {
        return ''
      }

      const date = GetAwayRate.getDeadlineDate(selectedRate)
      if (!date) {
        return ''
      }

      return dateToDDMMYYYWithTime(date)
    })

    const refundDeadlinePriceStr = computed(() => {
      if (!getAwayPurchase.value) {
        return ''
      }

      const selectedRate = GetAwayPurchase.getSelectedRate(getAwayPurchase.value)
      if (!selectedRate) {
        return ''
      }

      const price = GetAwayRate.getDeadlinePrice(selectedRate)
      if (!price) {
        return ''
      }

      return Money.toString(price, 2)
    })

    const backUrl = computed(() => {
      if (!getAwayPurchase.value) {
        return ''
      }

      return `/purchase/${getAwayPurchase.value.uuid}/checkout/buyer`
    })

    useNavPopStateReload()

    return {
      getAwayPurchase,
      paymentMethodCollection,
      hasPaymentMethodSelected,

      isRefundable,
      refundDeadlineDateWithTimeStr,
      refundDeadlinePriceStr,

      getIsPaymentMethodSelected,
      handlePaymentMethodInput,

      preparePayment,

      backUrl,
    }
  }
})
</script>

<style lang="scss" scoped>
@import url('~/core/components/shared/form/form-table.scss');
.header {
  @apply text-xl;
  @apply font-bold;
  @apply text-spl-dark;
  @apply my-20p;
}

.subheader {
  @apply italic;
  @apply text-spl-gray-4;
  @apply my-5p;
  @apply text-base;
}

.badge {
  @apply my-10p;
}

.payment-selector {
  @apply mt-6;
  @apply py-4;

  @apply border-solid;
  @apply border-t-2;
}
</style>
