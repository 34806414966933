import es from './lang/es'
import pt from './lang/pt'

import { isADynamicURL } from './util/string'

export const supportedLangs = [
  'es',
  'pt'
]

export const lang: Record<string, Record<'singular' | 'plural', Record<string, string>>> = {
  es,
  pt,
}

let envLangCache = ''
export function getCurrentENVLanguage() {
  if (!envLangCache.length) {
    const config = useRuntimeConfig()
    envLangCache = config.public.language
  }

  return envLangCache
}

export function getCurrentURLLanguage() {
  const route = useRoute()
  const urlLang = route.params.lang as string

  if (urlLang) {
    return urlLang
  }

  // Home en Español
  if (route.path === '/') {
    return 'es'
  }

  if (isADynamicURL(route.path)) {
    return 'es'
  }

  return null
}

export function getCurrentLanguage() {
  const urlLang = getCurrentURLLanguage()
  if (urlLang) {
    return urlLang
  }

  return getCurrentENVLanguage()
}

export function langToCountryISO2(lang: string): string {
  const map: Record<string, string> = {
    es: 'ES',
    pt: 'PT',
  }

  const code = map[lang] || 'ES'
  return code
}
