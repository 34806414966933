<template>
  <HomePortugal />
</template>

<script lang="ts">
import HomePortugal from '~/core/pages/home/HomePortugal.page.vue'

definePageMeta({
  layout: 'home',
})

export default defineNuxtComponent({
  components: {
    HomePortugal,
  }
})
</script>
