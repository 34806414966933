<template>
  <div>
    <GeneralGA4Analitics />
    <GeneralLoading />
    <GeneralNotificator />
    <GeneralLogger />
    <GeneralAdBlockDetector />

    <Sentry />

    <!-- TODO: Cambiar esto por un nuxt-page para que los layout vayan dentro de cada página y así poder pasarles parámetros -->
    <nuxt-layout />
  </div>
</template>

<script lang="ts">
import GeneralGA4Analitics from '~/core/components/singleton/GeneralGA4Analitics.vue'
import GeneralLoading from '~/core/components/singleton/GeneralLoading.vue'
import GeneralNotificator from '~/core/components/singleton/GeneralNotificator.vue'
import GeneralLogger from './core/components/singleton/GeneralLogger.vue'
import GeneralAdBlockDetector from './core/components/singleton/GeneralAdBlockDetector.vue'

import Sentry from '~/core/components/singleton/Sentry.vue'

import { useFetchProxy } from './core/composable/shared/useFetchProxy'
import { useSessionLocation } from './core/composable/shared/useSessionLocation'

import type { Location } from './core/ts/entity/Location'
import { useContext } from './core/composable/shared/useContext'
import type { PromotionLanding } from './core/ts/entity/PromotionLanding'
import type { TreatmentLanding } from './core/ts/entity/TreatmentLanding'

export default defineNuxtComponent({
  components: {
    GeneralGA4Analitics,
    GeneralLoading,
    GeneralNotificator,
    GeneralLogger,
    GeneralAdBlockDetector,
    Sentry,
  },
  async setup() {
    const route = useRoute()
    const uri = route.path

    const context = useContext()

    onMounted(() => {
      if (currentLocationBasedOnURL) {
        sessionLocation.storeInSession(currentLocationBasedOnURL)
        return
      }

      // No estamos en una landing con localización.
      //  Si tenemos algo almacenado, redirección.
      sessionLocation.readFromSession()

      const storedLoc = sessionLocation.storedLocation.value
      if (!storedLoc) {
        return
      }

      switch (context.type.value) {
        case 'spas': {
          const landing = storedLoc.landingURLs.spas
          if (landing.payloadLength !== 0) {
            location.replace(landing.url)
          }
        } break

        case 'getAways': {
          const landing = storedLoc.landingURLs.getAways
          if (landing.payloadLength !== 0)  {
            location.replace(landing.url)
          }

        } break

        case 'promotions': {
          if (!posiblePromotion) {
            return
          }

          const landing = storedLoc.landingURLs.promotions[posiblePromotion.tagUuid]
          if (!landing) {
            return
          }
          if (!landing.url) {
            return
          }

          location.replace(landing.url)
        } break

        case 'treatments': {
          console.warn(posibleTreatment)
          if (!posibleTreatment) {
            return
          }

          const landing = storedLoc.landingURLs.treatments[posibleTreatment.tagUuid]
          if (!landing) {
            return
          }
          if (!landing.url) {
            return
          }

          location.replace(landing.url)
        } break
      }
    })

    async function getCurrentLocation(): Promise<Location | null> {
      if (context.type.value === 'dynamic' || context.type.value === 'homes') {
        return null
      }

      return await useFetchProxy<Location | null>('/api/shared/get-location-for-uri', {
        method: 'post',
        body: { uri }
      })
    }

    async function getPromotionIfItIsTheCase(): Promise<PromotionLanding | null> {
      if (context.type.value !== 'promotions') {
        return null
      }

      return await useFetchProxy<PromotionLanding>(
        '/api/promotions/promotion-landing-by-uri',
        { method: 'post', body: { uri: route.path } }
      )
    }

    async function getTreatmentIfItIsTheCase(): Promise<TreatmentLanding | null> {
      if (context.type.value !== 'treatments') {
        return null
      }

      return await useFetchProxy<TreatmentLanding>(
        '/api/treatments/treatment-landing-by-uri',
        { method: 'post', body: { uri: route.path } }
      )
    }

    async function getAllLocations(): Promise<Array<Location>> {
      if (context.type.value === 'dynamic' || context.type.value === 'homes') {
        return []
      }

      return await useFetchProxy<Array<Location>>('/api/locations/location-list-with-any-landing', {})
    }

    const posiblePromotion = await getPromotionIfItIsTheCase()
    const posibleTreatment = await getTreatmentIfItIsTheCase()

    const currentLocationBasedOnURL = await getCurrentLocation()
    const allLocations = await getAllLocations()

    const sessionLocation = useSessionLocation(allLocations)

    return {
      allLocations,
    }
  },
})
</script>

<style lang="scss">
// Util SCSS clases
.util-snippet {
  @apply h-0;
  @apply opacity-0;
  display: none;
}

.only-mobile {
  @screen lg {
    @apply hidden;
  }
}

.only-desktop-block {
  @apply hidden;
  @screen lg {
    @apply block;
  }
}

.cm-body {
  display: none;
}

// .cn-decline, .cm-btn-decline {
//   display: none !important;
// }

.cm-btn-accept {
  // display: none;
  background-color: #557f9c !important;
  color: white;
}

.cm-btn-accept-all {
  background: #4d9907 !important;
  color: white;
}

.cm-powered-by a {
  display: hidden !important;
}

.cm-footer-buttons {
  justify-content: end !important;
}

</style>
