<template>
  <div class="ko">
    <Card
      mobile-with-border
      desktop-with-border
      class="ko__error"
    >
      <h2>Lo sentimos.</h2>
      <p>Ha ocurrido un error, por favor, póngase en contacto con nuestro servicio técnico:</p>
      <div class="ko__error__email">
        <a href="mailto:ayuda@spalopia.com">ayuda@spalopia.com</a>
      </div>
      <p class="ko__error__sad">
        Disculpe las molestias.
      </p>
    </Card>
  </div>
</template>

<script lang="ts">
import Card from '~/core/components/shared/Card.vue'

definePageMeta({
  layout: 'thanks',
})

export default defineNuxtComponent({
  components: {
    Card,
  },
})
</script>

<style lang="scss" scoped>
.ko {
  @apply mx-2;
  @apply flex;
  @apply items-center justify-center;
  flex-direction: column;

  @screen lg {
    @apply p-8;
    @apply mb-3;
    @apply mx-20;
  }

  h2 {
    @apply text-3xl;
    @apply text-green-900;
  }

  &__loading-data {
    @apply py-8 px-12;
    @apply w-2/3;

    @screen lg {
      @apply w-1/3;
    }

    h2 {
      @apply py-4;
      @apply text-xl;
      @apply font-semibold;

      @apply text-center;
    }
  }

  &__error {
    @apply text-center;
    @apply py-6;
    @apply px-8;

    @apply my-8;

    @screen lg {
      @apply mx-12;
      @apply my-2;
    }

    h2 {
      @apply mb-4;
    }

    &__sad {
      @apply text-spl-primary-dark;
    }

    p {
      @apply text-xl;
      @apply mt-2;
    }

    &__email {
      @apply text-xl;
      @apply font-semibold;
      @apply text-green-900;
      @apply mt-2;
      @apply mb-4;
    }
  }
}
</style>
