import {v4 as uuidv4} from 'uuid'

export function addDomainIfNeeded(uri: string, domain: string): string {
  if (uri.includes('http://') || uri.includes('https://')) {
    return uri
  }

  return joinPaths(domain, uri)
}

export function unslashUri(uri: string): string {
  const unslashed = uri.replaceAll('/', ' ')
  const trimed = unslashed.trim()

  const final = trimed.replaceAll(' ', '_')

  return final
}

export function reslashUnslashedUri(uri: string): string {
  const reslashed = uri.replaceAll('_', '/')

  return slashUri(reslashed)
}

export function removePartFromURL(url: string, part: string): string {
  const cleaned = unslashUri(url)

  return cleaned.split('_').filter((p) => p != part).join('/')
}

export function urlHasPart(url: string, needle: string): boolean {
  const parts = url.split('/')

  for (const part of parts) {
    if (part === needle) {
      return true
    }
  }

  return false
}

export function slashUri(uri: string): string {
  let final = addFirstSlashToURL(uri)
  final = addLastSlashToURL(final)
  return final
}

export function addLastSlashToURL(url: string): string {
  const lastCharacter = url.charAt(url.length - 1)
  return lastCharacter === '/' ? url : `${url}/`
}

export function addFirstSlashToURL(url: string): string {
  if (url.includes('http://') || url.includes('https://')) {
    return url
  }

  const lastCharacter = url.charAt(0)
  return lastCharacter === '/' ? url : `/${url}`
}

export function removeProtocolFromUrl(url: string): string {
  return url.replaceAll('http://', '').replaceAll('https://', '')
}

export function joinPaths(back: string, front: string) {
  const tmpFront = front.replaceAll('//', '/')

  const finalBack = back.endsWith('/') ? back.slice(0, back.length - 1) : back
  const finalFront = tmpFront.startsWith('/') ? tmpFront.slice(1, tmpFront.length) : tmpFront

  return `${finalBack}/${finalFront}`
}

export function isAlphaNumeric(text: string): boolean {
  for (let i = 0; i < text.length; i++) {
    const code = text.charCodeAt(i)

    const isNotNumeric =
      !(code > 47 && code < 58) &&  // numeric (0-9)
      !(code > 64 && code < 91) &&  // upper alpha (A-Z)
      !(code > 96 && code < 123)    // lower alpha (a-z)

    if (isNotNumeric) {
      return false
    }
  }

  return true
}

export function capitalizeFirstWord(phrase: string): string {
  const lowerPhrase = phrase.trimStart()
  return lowerPhrase.charAt(0).toUpperCase() + lowerPhrase.slice(1)
}

export function capitalizeAllLargeWords(phrase: string): string {
  const words = phrase.trim().split(' ')

  const converted = words.map((element: string) => {
    if (element.length <= 2) {
      return element
    }

    return element.charAt(0).toUpperCase() + element.substring(1).toLowerCase()
  })

  return converted.join(' ')
}

export function capitalizeAllWords(phrase: string): string {
  const words = phrase.toLowerCase().split(' ')

  const converted = words
    .map((element: string) =>  element.charAt(0).toUpperCase() + element.substring(1).toLowerCase())

  return converted.join(' ')
}

const htmlEntitiesReplacements = {
  '&amp;': '&',
}

export function removeHTMLEntities(text: string): string {
  return Object.entries(htmlEntitiesReplacements).reduce((prev: string, actual: string[]) => {
    const [htmlEntity, replaceText] = actual
    return prev.replace(new RegExp(htmlEntity, 'g'), replaceText)
  }, text)
}

export function splitMiddleBySpace(text: string) {
  let last = 0

  let count = Math.ceil(text.length / 2)

  count = Math.min(Math.max(17, count), text.length)

  for (let i = 0; i < count; i++) {
    const letter = text.charAt(i)

    if (letter === ' ') {
      last = i
    }
  }

  if (last) {
    return [text.slice(0, last).trim(), text.slice(last).trim()]
  }

  return [text, '']
}

export function isValidEmailString(email: string): boolean {
  const trimmedEmail = email.trim()

  if (trimmedEmail.includes(' ')) {
    return false
  }

  return /(.+)@(.+){2,}\.(.+){2,}/.test(trimmedEmail)
}

export function isEqualCaseInsensitive(a: string, b: string): boolean {
  return a.toLocaleLowerCase() == b.toLocaleLowerCase()
}

export function removeVowelAccents(text: string): string {
  return text
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
}

export function normalizeForSearch(text: string): string {
  return removeVowelAccents(text)
    .toLowerCase()
}

export function includesInsensitive(text: string, needle: string): boolean {
  const cleanNeedle = normalizeForSearch(needle)
  return normalizeForSearch(text).includes(cleanNeedle)
}


export function includesWordInsensitive(text: string, needle: string): boolean {
  const cleanNeedle = normalizeForSearch(needle)
  return normalizeForSearch(text)
    .split(' ')
    .includes(cleanNeedle)
}

let index = 0
const str = 'QWERTYUIOPASDFGHJKLZXCVBNM'
export function generateSmallUUID() {
  index += Math.ceil(Math.random() * 10)

  const time = Date.now() + index
  const id = (time + '').substring(8)

  let alpha = ''
  for (let i = 0; i < 3; i++) {
    const letterIndex = Math.floor(Math.random() * str.length)
    alpha += str.charAt(letterIndex)
  }

  return id + alpha
}

export function generateV4UUID(): string {
  return uuidv4()
}

export function objectToBase64(obj: object): string {
  const json = JSON.stringify(obj)
  return btoa(json)
}

export function objectFromBase64(code: string): object {
  const json = atob(code)
  return JSON.parse(json)
}

export function isADynamicURL(url: string): boolean {
  if (url.includes('/purchase/')) {
    return true
  }

  if (url.includes('/voucher/')) {
    return true
  }

  return false
}
