import type { Location } from '../entity/Location'
import LocalStorageProxy from '../shared/LocalStorageProxy'

export default class LocationSessionRepository {
  public static store(lang: string, location: Location) {
    LocalStorageProxy.setItem(`location-session-value-${lang}`, JSON.stringify(location))
    LocalStorageProxy.setItem(`location-session-time-${lang}`, Date.now().toString())
  }

  public static read(lang: string): Location | null {
    if (!this.isValid(lang)) {
      console.warn('not valid')
      return null
    }

    const locStr = LocalStorageProxy.getItem(`location-session-value-${lang}`) as string
    return JSON.parse(locStr) as Location
  }

  public static remove(lang: string, ): void {
    LocalStorageProxy.removeItem(`location-session-value-${lang}`)
    LocalStorageProxy.removeItem(`location-session-time-${lang}`)
  }

  public static isValid(lang: string) {
    const locStr = LocalStorageProxy.getItem(`location-session-value-${lang}`)
    if (!locStr) {
      return false
    }

    const timeStr = LocalStorageProxy.getItem(`location-session-time-${lang}`)
    if (!timeStr) {
      return false
    }

    const time = Number(timeStr)
    const now = Date.now()

    const timeDiff = now - time

    return timeDiff < 1000 * 60 * 60
  }
}
