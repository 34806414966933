<template>
  <GetAwayCheckoutWrapper
    v-if="getAwayPurchase"
    :step-index="0"
    :get-away-purchase="getAwayPurchase"
  >
    <form
      class="buyer-form"
      @submit.prevent="validateAndSend"
    >
      <div class="form-wrapper">
        <div class="form-box">
          <div class="header">
            <h3>
              <DynLang
                by-key="checkout-getaway-form-title"
                capitalize-first
              />
            </h3>
          </div>

          <div class="form-table">
            <div class="form-table-top-left form-table-row form-table-double-mobile">
              <SplInputBox
                id="buyer-name"
                v-model="buyer.name"
                label-translation-key="name"
                variant="base"
                type="text"
                :has-border="false"
                :is-valid="isNameValid"
                :max-length="20"
                @blur="onNameBlur"
              />
            </div>
            <div class="form-table-row form-table-double-mobile">
              <SplInputBox
                id="buyer-surname"
                v-model="buyer.surname"
                label-translation-key="surname"
                type="text"
                variant="base"
                :has-border="false"
                :is-valid="isSurnameValid"
                :max-length="26"
                @blur="onSurnameBlur"
              />
            </div>

            <div class="form-table-row form-table-double">
              <SplEmailInput
                id="buyer-email"
                v-model="buyer.email"
                :is-valid="isEmailValid"
                @blur="onEmailBlur"
              />
            </div>

            <div class="form-table-bottom-left form-table-bottom-right form-table-row form-table-double">
              <SplInputBox
                id="buyer-phone"
                v-model="buyer.phone"
                type="tel"
                variant="base"
                :has-border="false"
                label-translation-key="phone"
                :max-length="20"
                :is-valid="isPhoneValid"
                @blur="onPhoneBlur"
                @on-update="() => {}"
              />
            </div>

            <div
              v-if="formErrors.length"
              class="form-errors"
            >
              <div
                v-for="(error, i) in formErrors"
                :key="`vp-error-${i}`"
              >
                <span v-if="error.key === 'name'">
                  *<DynLang
                    by-key="buyer-form-name-error"
                    capitalize-first
                  />
                </span>
                <span v-else-if="error.key === 'surname'">
                  *<DynLang
                    by-key="buyer-form-surname-error"
                    capitalize-first
                  />
                </span>
                <span v-else-if="error.key === 'email'">
                  *<DynLang
                    by-key="buyer-form-email-error"
                    capitalize-first
                  />
                </span>
                <span v-else-if="error.key === 'phone'">
                  *<DynLang
                    by-key="buyer-form-phone-error"
                    capitalize-first
                  />
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="form-box">
          <div class="form-checks">
            <SplCheckbox
              id="offer-approve"
              v-model="buyer.allowNotifications"
              class="form-checks-item"
            >
              <DynLang
                by-key="offer-accept-label"
                capitalize-first
              />
            </SplCheckbox>

            <SplCheckbox
              id="terms-and-conditions"
              v-model="termsAndConditions"
              class="form-checks-item"
              :is-valid="termsAndConditionsValid"
              tooltip-translatable-content-key="purchase-has-to-accept-conditions"
            >
              <DynLang
                by-key="ive-read-and-accept"
                capitalize-first
              />
              <SplNuxtLink
                is-external
                is-target-blank
                class="form-checks-item__terms"
                to="/condiciones-generales-compra"
              >
                <DynLang
                  by-key="terms-and-conditions"
                  capitalize-first
                />
              </SplNuxtLink>
            </SplCheckbox>

            <div
              v-if="formErrors.length"
              class="form-errors"
            >
              <div
                v-for="(error, i) in formErrors"
                :key="`vp-error-${i}`"
              >
                <span v-if="error.key === 'termsAndConditions'">
                  *<DynLang
                    by-key="buyer-form-terms-error"
                    capitalize-first
                  />
                </span>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="form-btns">
            <div>
              <SplNuxtLink
                is-external
                no-lang
                :to="backUrl"
                :query="backQuery"
                class="form-btns-back"
              >
                <DynLang
                  by-key="back"
                  capitalize-first
                />
              </SplNuxtLink>
            </div>

            <div>
              <SplButton
                class="form-btns-contlarge"
                type="submit"
                variant="acent"
                size="lg"
              >
                <DynLang
                  by-key="continue"
                  capitalize-all
                />
              </SplButton>
              <SplButton
                class="form-btns-contsmall"
                type="submit"
                variant="acent"
                size="sm"
              >
                <DynLang
                  by-key="continue"
                  capitalize-all
                />
              </SplButton>
            </div>
          </div>
        </div>
      </div>
    </form>
  </GetAwayCheckoutWrapper>
</template>

<script lang="ts">
import GetAwayCheckoutWrapper from '~/core/components/get-away/GetAwayCheckoutWrapper.vue'
import SplInputBox from '~/core/components/shared/form/SplInputBox.vue'
import SplEmailInput from '~/core/components/shared/form/SplEmailInput.vue'
import SplCheckbox from '~/core/components/shared/form/SplCheckbox.vue'

import SplButton from '~/core/components/shared/form/SplButton.vue'
import SplNuxtLink from '~/core/components/shared/SplNuxtLink.vue'

import { useGetAwayPurchaseBuyerForm } from '~/core/composable/get-away/useGetAwayPurchaseBuyerForm'
import { useNavPopStateReload } from '~/core/composable/shared/useNavPopStateReload'
import { useLangSelector } from '~/core/composable/shared/useLangSelector'

import { dateFromSecodsTimeStamp, dateToYYYYMMDD } from '~/core/ts/util/date'
import { translateForLang } from '~/core/ts/util/translate'

definePageMeta({
  layout: 'checkout',
})

export default defineNuxtComponent({
  components: {
    GetAwayCheckoutWrapper,
    SplInputBox,
    SplEmailInput,
    SplCheckbox,
    SplButton,
    SplNuxtLink,
  },
  setup() {
    const {
      getAwayPurchase,
      buyer,
      formErrors,

      isNameValid,
      isSurnameValid,
      isEmailValid,
      isPhoneValid,

      onNameBlur,
      onSurnameBlur,
      onEmailBlur,
      onPhoneBlur,

      termsAndConditions,
      termsAndConditionsValid,

      validateAndSend,
    } = useGetAwayPurchaseBuyerForm()

    const {
      currentLang,
    } = useLangSelector()

    useNavPopStateReload()

    const backUrl = computed(() => {
      if (!getAwayPurchase.value) {
        return '/'
      }

      const getAway = getAwayPurchase.value.getAwayAvailability.getAway

      return translateForLang(getAway.texts, currentLang).uri
    })

    const backQuery = computed(() => {
      if (!getAwayPurchase.value) {
        return {}
      }

      const availability = getAwayPurchase.value.getAwayAvailability

      const fromDate = dateFromSecodsTimeStamp(availability.from)
      const toDate = dateFromSecodsTimeStamp(availability.to)

      return {
        from: dateToYYYYMMDD(fromDate),
        to: dateToYYYYMMDD(toDate),
        'date-range': dateToYYYYMMDD(fromDate) + ',' + dateToYYYYMMDD(toDate),
        pax: availability.numPax,
      }
    })

    return {
      getAwayPurchase,

      backUrl,
      backQuery,

      buyer,
      formErrors,

      isNameValid,
      isSurnameValid,
      isEmailValid,
      isPhoneValid,

      onNameBlur,
      onSurnameBlur,
      onEmailBlur,
      onPhoneBlur,

      termsAndConditions,
      termsAndConditionsValid,

      validateAndSend,
    }
  }
})
</script>

<style lang="scss" scoped>
@import url('~/core/components/shared/form/form-table.scss');
.buyer-form {
  @apply text-lg;
  @apply mb-6;
}

.header {
  @apply text-xl;
  @apply font-bold;
  @apply text-spl-dark;
  @apply my-20p;
}
</style>
