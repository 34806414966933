<template>
  <div class="package-purchase-start">
    <Card
      v-if="hasErrors"
      class="package-purchase-start__error-box"
    >
      <h3>
        <DynLang by-key="an-error-has-ocurred" />
      </h3>
      <p>
        <DynLang
          by-key="the-purchase-could-not-be-started"
          capitalize-first
        />
      </p>

      <p>
        <DynLang
          by-key="please-try-it-again-later"
          capitalize-first
        />
      </p>

      <p class="margined">
        <DynLang
          by-key="if-problems-persist-contact-support"
          capitalize-first
        />:
      </p>
      <span class="package-purchase-start__error-box__email">
        <a href="mailto:ayuda@spalopia.com">ayuda@spalopia.com</a>
      </span>

      <div class="package-purchase-start__error-box__back-btn">
        <SplButton
          variant="primary"
          @click="goBackClick"
        >
          <DynLang
            by-key="go-back"
            capitalize-all
          />
        </SplButton>
      </div>
    </Card>

    <div
      v-else
      class="package-purchase-start__loading-box"
    >
      <LoadingSpinner :size="48" />
    </div>
  </div>
</template>

<script lang="ts">
import LoadingEventBus from '~/core/events/loading'

import LoadingSpinner from '@/core/components/shared/LoadingSpinner.vue'
import Card from '~/core/components/shared/Card.vue'
import SplButton from '~/core/components/shared/form/SplButton.vue'

import { useLangSelector } from '~/core/composable/shared/useLangSelector'
import { useNavPopStateReload } from '~/core/composable/shared/useNavPopStateReload'

import { GetAwayPrePurchase } from '~/core/ts/entity/GetAwayPrePurchase'
import GetAwayPurchaseAPIRepository from '~/core/ts/repository/get-away/GetAwayPurchaseAPIRepository'
import { joinPaths } from '~/core/ts/util/string'

definePageMeta({
  layout: 'checkout',
})

export default defineNuxtComponent({
  components: {
    LoadingSpinner,
    Card,
    SplButton,
  },
  setup() {
    const route = useRoute()
    const runtime = useRuntimeConfig()

    const { addCurrentOrOverrideLanguageToURL } = useLangSelector()

    const hasErrors = ref(false)

    function goBackClick() {
      if (history.length > 1) {
        history.back()
        return
      }

      location.replace(joinPaths(runtime.public.domain, runtime.public.urls.spasBaseUrl))
    }

    async function navigateToCheckout() {
      LoadingEventBus.show()

      const query = route.query
      const { purchase: prePurchaseEncoded } = query

      try {
        const prePurchase = GetAwayPrePurchase.fromEncoded(prePurchaseEncoded as string)

        await GetAwayPurchaseAPIRepository.start(prePurchase)

        const targetURL = addCurrentOrOverrideLanguageToURL(
          `/purchase/${prePurchase.uuid}/checkout/buyer`,
          prePurchase.langCode
        )

        location.replace(targetURL)
      } catch (e) {
        hasErrors.value = true
      }

      LoadingEventBus.hide()
    }

    onMounted(() => {
      window.scrollTo(0, 0)
      setTimeout(() => navigateToCheckout(), 500)
    })

    useNavPopStateReload()

    return {
      hasErrors,

      goBackClick,
    }
  },
})
</script>

<style lang="scss" scoped>
.package-purchase-start {
  @apply py-4;
  @apply text-lg;

  &__loading-box {
    @apply text-center;

    @apply mt-8;
    @apply mb-12;

    @apply pt-6;
    @apply pb-8;

    @apply mx-1;
    @screen lg {
      @apply w-2/3;
      @apply mx-auto;
    }
  }

  &__error-box {
    @apply w-full;
    @apply mx-auto;
    @apply bg-white;

    @screen md {
      @apply w-1/2;
    }

    @apply px-8;
    @apply py-4;

    @apply mt-8;
    @apply mb-12;

    h3 {
      @apply py-4;
      @apply text-xl;
      @apply text-spl-primary-dark;
      @apply font-bold;
    }

    .margined {
      @apply pt-4;
    }

    &__email {
      @apply py-1;
      @apply text-spl-dark;
    }

    &__back-btn {
      @apply py-8;
      @apply text-center;
    }
  }
}
</style>
